import standard from './standard';
import pavillon from './pavillon';

const monthList = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
];

const selector = (workbook) => {
  // Extract first sheet parameters
  const worksheet = workbook.worksheets[0];

  // Extract data to determine the file type
  const month = worksheet.getCell('H9').value;

  if (monthList.includes(month)) return standard(workbook);
  else return pavillon(workbook);
};

export default selector;
