import { useState } from 'react';
import Badge from './Badge';

const renderAccept = (str) =>
  str === '*'
    ? ''
    : str
        .split(',')
        .map((o) => o.replace('.', '').toUpperCase())
        .join(', ');

const cancelEffect = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const SingleInputFile = (props) => {
  const {
    label = '',
    accept = '*',
    setFile = () => {},
    defaultValue = undefined,
    valueKey = (f) => f?.name,
    name = '',
    className = '',
    ...rest
  } = props;

  const [preview, setPreview] = useState(defaultValue);
  const [toBeDeleted, setToBeDeleted] = useState(null);

  const removeFile = () => {
    if (preview?.id) setToBeDeleted(preview?.id);

    setPreview(null);
  };

  const uploadFile = (e, type = 'click') => {
    e.preventDefault();
    e.stopPropagation();

    let files = null;

    if (type === 'click') files = e.target.files;
    else files = e.dataTransfer.files;

    setFile(files.item(0));
    setPreview([]);
    setPreview(files.item(0));
  };

  return (
    <div className={className}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      {toBeDeleted && (
        <input
          type="hidden"
          name={['deleted.', name?.split('.')?.at(-1)].join('')}
          value={toBeDeleted}
        />
      )}
      {preview && (
        <Badge className="mt-1 mr-2" onClick={() => removeFile()}>
          {valueKey(preview)}
        </Badge>
      )}

      <div
        className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md h-full ${
          preview && 'hidden'
        }`}
        onDrag={(e) => cancelEffect(e)}
        onDragEnter={(e) => cancelEffect(e)}
        onDragLeave={(e) => cancelEffect(e)}
        onDragEnd={(e) => cancelEffect(e)}
        onDragOver={(e) => cancelEffect(e)}
        onDragStart={(e) => cancelEffect(e)}
        onDrop={(e) => uploadFile(e, 'DND')}>
        <div className="space-y-1 text-center flex flex-col justify-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true">
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
              <span>Upload a file</span>
              <input
                type="file"
                className="sr-only"
                accept={accept}
                onChange={(e) => uploadFile(e)}
                name={name}
                {...rest}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">{renderAccept(accept)}</p>
        </div>
      </div>
    </div>
  );
};

export default SingleInputFile;
