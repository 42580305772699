import moment from 'moment';
const capitalize = (str) => [str[0].toUpperCase(), str.slice(1).toLowerCase()].join('');

const pavillon = (workbook) => {
  // Extract first sheet parameters
  const worksheet = workbook.worksheets[0];
  const lastLine = worksheet.lastRow._number;

  // Extract data
  const month = moment(worksheet.getCell('E2').value, 'DD/MM/YYYY').month();
  const rows = worksheet.getRows(2, lastLine);

  // First extract and build names
  const keys = [];

  for (let row of rows) {
    const values = row.values;

    if (values?.at(1) && !values?.at(1)?.toLowerCase()?.includes('total')) {
      const name = `${values?.[2]?.toUpperCase()} ${capitalize(values?.[3])}`;

      if (!keys.includes(name)) keys.push(name);
    }
  }

  // Build a program friendly structure
  const result = keys.map((name) => {
    const rawTable = new Array(
      moment(worksheet.getCell('E2').value, 'DD/MM/YYYY').daysInMonth() + 2
    ).fill(0);

    // Fill specific data
    rawTable[0] = name;
    rawTable[1] = 'J';

    return rawTable;
  });

  // Fill the structure with the data collected in the file
  for (let row of rows) {
    const values = row.values;

    if (values?.at(1) && !values?.at(1)?.toLowerCase()?.includes('total')) {
      const name = `${values?.[2]?.toUpperCase()} ${capitalize(values?.[3])}`;
      const date = moment(values?.[5], 'DD/MM/YYYY').date();
      const value = values?.[9];

      const index = result.findIndex((o) => o[0] === name);
      result[index][date + 1] += value;
    }
  }

  return {
    month,
    data: result
  };
};

export default pavillon;
