import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';

import { Upload, Selection, Validation, Resume, Import } from '@pages';

const router = createBrowserRouter([
  { path: '/', loader: async () => redirect('/depot') },
  { path: '/depot', element: <Upload /> },
  { path: '/selection', element: <Selection /> },
  { path: '/validation', element: <Validation /> },
  { path: '/resume', element: <Resume /> },
  { path: '/rhimport', element: <Import /> }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
