import { useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from '@layouts';
import moment from 'moment';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Validation() {
  const location = useLocation();
  const navigate = useNavigate();

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  // Get data from previous screens
  const { data, month } = location.state;

  // Generate month sequence
  const daysInMonth = Array(moment().month(month).daysInMonth())
    .fill(0)
    .map((_, index) => index + 1);

  // Used to display checkbox colors and check / uncheck feature
  useLayoutEffect(() => {
    const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < data.length;
    setChecked(selectedPeople.length === data.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedPeople]);

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : data);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const submit = () =>
    navigate('/resume', {
      state: {
        ...location.state,
        selected: selectedPeople
      }
    });

  return (
    <Layout>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Validation</h1>
            <p className="mt-2 text-sm text-gray-700">
              Ecran de validation des données, vous avez la possibilité de vérifier les informations
              et choisir lesquels insérer.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              onClick={() => submit()}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto">
              Suivant
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500 sm:left-6"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      <th
                        scope="col"
                        className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                        Nom
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        J/N
                      </th>
                      {daysInMonth.map((o) => (
                        <th
                          key={o}
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          {o}
                        </th>
                      ))}
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Somme
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((row, index) => (
                      <tr
                        key={index}
                        className={selectedPeople.includes(row) ? 'bg-gray-50' : undefined}>
                        <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                          {selectedPeople.includes(row) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-red-600" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500 sm:left-6"
                            value={row.at(0)}
                            checked={selectedPeople.includes(row)}
                            onChange={(e) =>
                              setSelectedPeople(
                                e.target.checked
                                  ? [...selectedPeople, row]
                                  : selectedPeople.filter((p) => p !== row)
                              )
                            }
                          />
                        </td>
                        {row.map((v, i) => (
                          <td
                            key={i}
                            className={classNames(
                              `${
                                i === 0 ? 'whitespace-nowrap pr-3 font-medium' : 'px-3'
                              } py-4 text-sm `,
                              selectedPeople.includes(row) ? 'text-red-600' : 'text-gray-900'
                            )}>
                            {v}
                          </td>
                        ))}
                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          {row.slice(2).reduce((prev, curr) => prev + curr, 0)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Validation;
