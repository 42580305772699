import { Steps } from '@components';
import { useLocation } from 'react-router-dom';

const getIndex = (path) => ['/depot', '/selection', '/validation', '/resume'].indexOf(path);

function Layout(props) {
  const { children = null } = props;
  const { pathname } = useLocation();

  const steps = [
    {
      id: 'Dépot fichier',
      name: 'Déposez la liste de présence',
      href: '/depot',
      status: 0 - getIndex(pathname)
    },
    {
      id: 'Sélection client',
      name: 'Sélectionner le client',
      href: '/selection',
      status: 1 - getIndex(pathname)
    },
    {
      id: 'Validation',
      name: 'Valider les informations',
      href: '/validation',
      status: 2 - getIndex(pathname)
    },
    {
      id: 'Résumé et correction',
      name: "Résumé et correction des cas d'erreurs",
      href: '/resume',
      status: 3 - getIndex(pathname)
    }
  ];

  return (
    <div className="flex flex-col p-10 h-screen space-y-10">
      <Steps className="flex justify-center w-full" steps={steps} />
      <div className="h-full">{children}</div>
    </div>
  );
}

export default Layout;
