import { useState, useEffect } from 'react';
import { getImport } from '@queries';

const columns = [
  'RHISOC',
  'RHIIND',
  'RHIAGE',
  'RHICLI',
  'RHIAFF',
  'RHIPER',
  'RHICTR',
  'RHISMT',
  'RHIDAT',
  'RHINBJ',
  'RHINHJ',
  'RHINHN',
  'RHIORG',
  'RHIDCR',
  'RHIHCR'
];

function Import() {
  const [value, setValue] = useState([]);

  useEffect(() => {
    getImport().then((data) => setValue(data?.data?.data));
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {columns.map((col, index) => (
                    <th
                      key={index}
                      scope="col"
                      className={`${
                        index === 0 ? 'pl-4 sm:pl-6 md:pl-0 pr-3' : 'px-3'
                      } py-3.5  text-left text-sm font-semibold text-gray-900`}>
                      {col}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {value.map((row, index) => (
                  <tr key={index}>
                    {columns.map((col, i) => (
                      <td key={i} className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                        {row[col.toLowerCase()]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Import;
