import { useState, useEffect } from 'react';
import { InputFile } from '@components';
import { Layout } from '@layouts';
import { useNavigate } from 'react-router-dom';
import selector from '@models';

import ExcelJS from 'exceljs';

function Upload() {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const buffer = reader.result;

        // Read Excel file
        const workbook = new ExcelJS.Workbook();
        const options = { dateFormats: ['DD/MM/YYYY'] };
        await workbook.xlsx.load(buffer, options);

        // const payload = standard(workbook);
        const payload = selector(workbook);
        console.log(payload);

        navigate('/selection', {
          state: payload
        });
      };

      reader.readAsArrayBuffer(file);
    }
  }, [file]);

  return (
    <Layout>
      <InputFile className="h-full" accept=".csv,.xlsx,.xls" setFile={setFile} />
    </Layout>
  );
}

export default Upload;
