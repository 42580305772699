export default function Stats(props) {
  const { stats = [] } = props;

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-md md:grid-cols-2 md:divide-y-0 md:divide-x">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-red-600">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-gray-500">
                  de {item.previousStat}
                </span>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
