import { useState, useEffect } from 'react';
import { Layout, Stats } from '@layouts';
import { Spinner } from '@components';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { getContrat, getPerson, insertData } from '@queries';

const extractName = (str) =>
  [
    ...str.matchAll(
      /([A-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞß -']+) ((?:[A-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞß]{1}[a-zàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ-]+\s*)+)/g
    )
  ]
    ?.at(0)
    .slice(1, 3);

const zip = (row) => {
  const [name, dayNight, ...rest] = row;
  return rest.map((v, index) => [name, dayNight, index, v]);
};

function Resume() {
  const location = useLocation();

  const { selected, client, affaire, month } = location.state;
  const [value, setValue] = useState([]);
  const [stats, setStats] = useState([]);

  useEffect(() => {
    // Transform selected
    const zipped = selected
      .map((row) => zip(row))
      .flat()
      .filter((row) => row[3] > 0);

    async function transform() {
      for (let rowIndex in zipped) {
        const [fullName, dayNight, index, value] = zipped[rowIndex];
        const [nom, prenom] = extractName(fullName);

        // Get intermediate values
        const person = await getPerson(prenom, nom).then(({ data }) => data.data[0]);
        const contracts = await getContrat(client, affaire, person).then(({ data }) => data.data);

        // Compute date
        const date = moment().month(month).startOf('month').add(index, 'day').format('DD/MM/YYYY');

        // Get contracts that matches
        const contract = contracts
          ?.filter(
            (c) =>
              moment(c?.ctcdeb).isSameOrBefore(moment(date, 'DD/MM/YYYY')) &&
              moment(c?.ctcfin).isSameOrAfter(moment(date, 'DD/MM/YYYY'))
          )
          ?.at(0);

        // If there is no contract, abort
        if (!contract) {
          zipped[rowIndex].push(false);
          continue;
        }

        // Else, insert
        insertData(client, affaire, person, contract, date, dayNight, value);
        zipped[rowIndex].push(true);
      }

      setValue(zipped);
    }

    transform();
  }, []);

  useEffect(() => {
    if (value?.length > 0) {
      setStats([
        {
          name: 'Réussis',
          stat: `${Math.floor((value.filter((v) => v[4]).length / value.length) * 100)}%`,
          previousStat: value.length
        },
        {
          name: 'En erreur',
          stat: `${Math.floor((value.filter((v) => !v[4]).length / value.length) * 100)}%`,
          previousStat: value.length
        }
      ]);
    }
  }, [value]);

  return (
    <Layout>
      <Stats stats={stats} />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                      J/N
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                      Date
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                      Valeur
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                      Statut
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {value?.length == 0 && (
                    <tr>
                      <td colSpan={5}>
                        <span className="flex justify-center items-center mt-5">
                          <Spinner className="text-black" />
                          Chargement ...
                        </span>
                      </td>
                    </tr>
                  )}
                  {value?.length > 0 &&
                    value.map((row) => (
                      <tr key={row[0]}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                          {row[0]}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                          {row[1]}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                          {moment()
                            .month(month)
                            .startOf('month')
                            .add(row[2], 'day')
                            .format('DD/MM/YYYY')}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                          {row[3]}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                          <span
                            className={`inline-flex rounded-full ${
                              !row[4] ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                            } px-2 text-xs font-semibold leading-5`}>
                            {!row[4] ? 'Absent' : 'Succès'}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Resume;
