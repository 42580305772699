const monthList = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
];

const standardModel = (workbook) => {
  // Extract first sheet parameters
  const worksheet = workbook.worksheets[0];
  const lastLine = worksheet.lastRow._number;

  // Extract data
  const month = monthList.indexOf(worksheet.getCell('H9').value);
  const rows = worksheet.getRows(12, lastLine - 12 + 1);

  const result = [];

  for (let row of rows) {
    // Extracting name and whether it is day or night
    const name = row.values[1];
    const dayNight = row.values[2];

    // Skip if not relevant
    if (!['J', 'N'].includes(dayNight)) continue;

    // Transform data
    const fillEmpty = Array.from(row.values, (v) => (v === undefined ? 0 : v));
    const replaceText = fillEmpty.map((v) => (typeof v === 'string' ? 0 : v));

    // Skip if fully empty
    if (replaceText.slice(3, -2).reduce((prev, curr) => prev + curr, 0) > 0)
      result.push([name, dayNight, ...replaceText.slice(3, -2)]);
  }

  return { month, data: result };
};

export default standardModel;
