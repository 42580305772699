import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function Steps(props) {
  const { steps = [], className = '' } = props;
  let { state } = useLocation();

  return (
    <nav aria-label="Progress" className={className}>
      <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.status < 0 ? (
              <Link
                to={step.href}
                className="group flex flex-col border-l-4 border-red-600 py-2 pl-4 hover:border-red-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                state={state}>
                <span className="text-sm font-medium text-red-600 group-hover:text-red-800">
                  {step.id}
                </span>
                <span className="text-sm font-medium">{step.name}</span>
              </Link>
            ) : step.status === 0 ? (
              <Link
                to={step.href}
                className="flex flex-col border-l-4 border-red-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                state={state}
                aria-current="step">
                <span className="text-sm font-medium text-red-600">{step.id}</span>
                <span className="text-sm font-medium">{step.name}</span>
              </Link>
            ) : (
              <Link
                to={'#'}
                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                state={state}>
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                  {step.id}
                </span>
                <span className="text-sm font-medium">{step.name}</span>
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
