import axios from 'axios';
import baseUrl from '@root/config';
import moment from 'moment';

export const getClients = () =>
  axios.post(
    `${baseUrl}/query`,
    { query: "SELECT TIASOC, TIARSO, TIAAGE, TIACLI from INSTIAP where TIAIND = 'CL1'" },
    { headers: { 'Content-Type': 'application/json' } }
  );

export const getAffaires = (client) =>
  axios.post(
    `${baseUrl}/query`,
    {
      query: `SELECT TIARSO, TIAAFF from INSTIAP where TIAIND = 'CL5' AND TIASOC = '${client?.tiasoc}' AND TIACLI = '${client?.tiacli}' AND TIAAGE = '${client?.tiaage}'`
    },
    { headers: { 'Content-Type': 'application/json' } }
  );

export const getPerson = (prenom, nom) =>
  axios.post(
    `${baseUrl}/query`,
    {
      query: `SELECT PERTIP, PERPRE, PERNOM, PERMAN, PERMAT FROM PERPERP WHERE PERIND = 'PRA' AND PERPRE LIKE '%${prenom}%' AND PERNOM = '${nom}'`
    },
    { headers: { 'Content-Type': 'application/json' } }
  );

export const getContrat = (client, affaire, person) =>
  axios.post(
    `${baseUrl}/query`,
    {
      query: `SELECT CTCPER, CTCCTR, CTCDEB, CTCFIN, CTCFMO from ETTCTCP where CTCIND = 'CTR' AND CTCSOC = '${client?.tiasoc}' AND CTCCLI = '${client?.tiacli}' AND CTCPER = '${person?.permat}' AND CTCAFF = '${affaire?.tiaaff}' AND CTCFMO NOT IN ('01', '02', '03', '04', '05') `
    },
    { headers: { 'Content-Type': 'application/json' } }
  );

export const getImport = () =>
  axios.post(
    `${baseUrl}/query`,
    {
      query:
        'SELECT RHISOC, RHIIND, RHIAGE, RHICLI, RHIAFF,  RHIPER,  RHICTR,  RHISMT,  RHIDAT,  RHINBJ,  RHINHJ,  RHINHN,    RHIORG  ,RHIDCR, RHIHCR FROM RHIMPORT'
    },
    { headers: { 'Content-Type': 'application/json' } }
  );

export const insertData = (client, affaire, person, contrat, date, dayNight, value) => {
  const query = `INSERT INTO RHIMPORT (RHISOC, RHIIND, RHIAGE, RHICLI, RHIAFF,  RHIPER,  RHICTR,  RHISMT,  RHIDAT,  RHINBJ,  RHINHJ,  RHINHN, RHIORG, RHIDCR, RHIHCR) VALUES ('${
    client?.tiasoc
  }', 'RHT', '${client?.tiaage}', '${client?.tiacli}', '${affaire?.tiaaff}', '${
    person?.permat
  }', '${contrat?.ctcctr}', '${moment(date, 'DD/MM/YYYY').format('YYYY')}${moment(
    date,
    'DD/MM/YYYY'
  )
    .isoWeek()
    .toString()
    .padStart(2, '0')}', '${moment(date, 'DD/MM/YYYY').format('YYYYMMDD')}', '${moment(
    date,
    'DD/MM/YYYY'
  ).isoWeekday()}', '${dayNight === 'J' ? value : 0}', '${
    dayNight === 'N' ? value : 0
  }', 'E', '${moment().format('YYYYMMDD')}', '${moment().format('HHmm')}' )`;

  return axios.post(
    `${baseUrl}/query`,
    {
      query,
      raw: true
    },
    { headers: { 'Content-Type': 'application/json' } }
  );
};
