import { useEffect, useState } from 'react';
import { Layout } from '@layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import { Combobox, Button, Alert } from '@components';
import { getClients, getAffaires } from '@queries';

function Selection() {
  const location = useLocation();
  const navigate = useNavigate();
  const { month, data } = location.state;

  // Get selection
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedAffaire, setSelectedAffaire] = useState(null);

  // Get APIs data
  const [clients, setClients] = useState([]);
  const [affaires, setAffaires] = useState([]);

  // Handle Errors
  const [displayAlert, setDisplayAlert] = useState(false);

  useEffect(() => {
    getClients().then(({ data }) => setClients(data?.data));
  }, []);

  useEffect(() => {
    setSelectedAffaire(null);
    getAffaires(selectedClient).then(({ data }) => setAffaires(data?.data));
  }, [selectedClient]);

  const submit = () => {
    if (selectedAffaire && selectedClient)
      navigate('/validation', {
        state: {
          month,
          data,
          client: selectedClient,
          affaire: selectedAffaire
        }
      });
    else setDisplayAlert(true);
  };

  return (
    <Layout>
      <div className="flex flex-1 justify-center">
        <div className="space-y-8 max-w-2xl w-full">
          {displayAlert && <Alert>Erreur, veuillez renseigner un client et une affaire</Alert>}
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">Sélection du client</h3>
            <p className="mt-1 text-sm text-gray-500">
              Sélectionnez le client ainsi que l&apos;affaire qui s&apos;y rapporte
            </p>
          </div>
          <Combobox
            label="Sélectionnez un client"
            valueKey={(d) => d?.index}
            data={clients}
            displayOptions={(p) => `${p?.tiacli} - ${p?.tiarso?.trim()}`}
            displayValue={(p) => (p ? `${p?.tiacli} - ${p?.tiarso?.trim()}` : null)}
            filter={(p, query) =>
              `${p?.tiacli} - ${p?.tiarso?.trim()}`.toLowerCase().includes(query.toLowerCase())
            }
            value={selectedClient}
            setValue={setSelectedClient}
            isControlled={true}
            allowSpace={true}
          />

          <Combobox
            label="Sélectionnez une affaire"
            valueKey={(d) => d?.index}
            data={affaires}
            displayOptions={(p) => `${p?.tiaaff} - ${p?.tiarso?.trim()}`}
            displayValue={(p) => (p ? `${p?.tiaaff} - ${p?.tiarso?.trim()}` : null)}
            filter={(p, query) =>
              `${p?.tiaaff} - ${p?.tiarso?.trim()}`.toLowerCase().includes(query.toLowerCase())
            }
            value={selectedAffaire}
            setValue={setSelectedAffaire}
            isControlled={true}
            allowSpace={true}
          />

          <div className="flex justify-end">
            <Button onClick={() => submit()}>Suivant</Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Selection;
